import React from 'react';
import Button from './button';

const IconButton = ({
  buttonId,
  icon,
  iconClassName = '',
  pathClassName = 'text-charcoal',
  onClick = null,
  className = 'text-charcoal',
  iconProps = {},
}) => {
  const Icon = icon;
  return (
    <Button
      id={buttonId}
      className={`
        flex items-center justify-center
        min-w-[44px] md:min-w-0
        min-h-[44px] md:min-h-0
        ${className}
      `}
      onClick={onClick}
    >
      <Icon iconId={buttonId+'_icon'} className={iconClassName} pathClassName={pathClassName} {...iconProps} />
    </Button>
  );
};

export default IconButton;

import React from 'react';

const IconAsterisk = ({
  className = '',
  pathClassName = 'text-charcoal',
}) => (
  <svg className={`${className}`} viewBox="0 0 161 156" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path className={`${pathClassName}`} fill="currentColor" d="M138.618 23.7592L125.784 13.1812L88.9269 56.3498V0H72.1736V56.3498L35.3161 13.1812L22.4831 23.7592L59.3405 66.9277L2.91508 57.0418L0 73.2547L56.4254 83.1406L6.70137 111.315L15.0781 125.584L64.7016 97.4093L45.1001 150.365L60.8483 156L80.4497 103.044L100.051 156L115.766 150.365L96.198 97.4093L145.788 125.584L154.165 111.315L104.575 83.1406L161 73.2547L158.085 57.0418L101.66 66.9277L138.618 23.7592Z" />
  </svg>
);

export default IconAsterisk;

/* eslint-disable no-unused-vars */
/* eslint-disable spaced-comment */
/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
import { useLocation } from '@reach/router';
import React, { useEffect, useRef, useState } from 'react';
import loadable from '@loadable/component';
import { useDispatch, useSelector } from 'react-redux';
import '../styles/locomotive-scroll.scss';
import {
  storeIsMobile, storeIsLandscape, storeIsTablet, toggleForm, selectIsMobile, selectIsTablet,
} from '../state/reducers/utils';
import { Intro } from './intro';
import { Constants } from '../constants';
import {
  OSANO_HTML_CUSTOMIZATION,
  OSANO_CC_FIRST_PARAGRAPH_HTML,
  OSANO_CC_SECOND_PARAGRAPH_HTML,
  OSANO_DO_NOT_SELL_OR_SHARE_LANGUAGE,
  OSANO_ANALYTICS_DESCRIPTION,
  OSANO_DNS_REVISION_PAR1,
  OSANO_DNS_REVISION_PAR2,
} from '../constants/osano';
import { ConnectForm } from './connect-form';
import Footer from './footer';
import Header from './header';
import LandscapeWarning from './landscape-warning';

const Scroll = loadable(() => import('./locomotive-scroll'));

const Layout = ({
  children, defaultHeaderColor, footerColor,
}) => {
  const dispatch = useDispatch();
  const currentWidth = useRef(null);
  const parentEl = useRef();
  const location = useLocation();
  const initIntro = location.pathname === '/';
  const [isIntro, setIsIntro] = useState(initIntro);
  const isMobile = useSelector(selectIsMobile);
  const isTablet = useSelector(selectIsTablet);
  const firstLoad = useRef(true);
  useEffect(() => {
    if (!firstLoad.current) window.location.reload();
  }, [isMobile, isTablet]);
  useEffect(() => {
    const queryString = location.search;
    const urlParams = new URLSearchParams(queryString);
    const formState = urlParams.get('form');
    if (formState === Constants.CREATORS || formState === Constants.BRANDS) {
      dispatch(toggleForm(true, formState));
    }
  }, [location.search]);
  useEffect(() => {
    const watchScreenSize = () => {
      dispatch(storeIsTablet(
        (
          window.innerWidth > window.innerHeight
          && window.innerWidth <= 1180
        )
        || (
          window.innerWidth < window.innerHeight
          && window.innerWidth <= 1024 && window.innerWidth >= 760
        ),
      ));
      dispatch(storeIsMobile(
        window.innerWidth <= Constants.MQ_MEDIUM
        || (window.innerWidth < Constants.MQ_LARGE && window.innerHeight < 500),
      ));
      if (window.innerWidth !== currentWidth.current) {
        currentWidth.current = window.innerWidth;
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        document.documentElement.style.setProperty('--rvh', `${vh}px`);
        dispatch(storeIsLandscape(window.innerWidth > window.innerHeight));
        if (window.locoScroll) {
          window.locoScroll.update();
        }
      } else {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--rvh', `${vh}px`);
      }
    };
    if (!isIntro) {
      window.addEventListener('resize', watchScreenSize);
      watchScreenSize();
    }
    return () => {
      window.removeEventListener('resize', watchScreenSize);
    };
  }, [isIntro]);
  useEffect(() => {
    if (!isIntro) parentEl.current.style.opacity = 1;
  }, [location.pathname, isIntro]);
  useEffect(() => {
    setTimeout(() => {
      firstLoad.current = false;
    }, 4000);

    const cp_interval = setInterval(() => {
      const root_el = document.querySelector('.osano-cm-content');
      if (root_el !== null) {
        // osano is showing....{

        // change accept button text
        const accept_btn = document.querySelector('.osano-cm-button--type_accept');
        if (accept_btn !== null) {
          accept_btn.innerHTML = 'Okay';
        } else {
          root_el.classList.add('no_button');
        }

        //remove the original, and add the new...
        const cm_anchor = document.querySelector('.osano-cm-link');
        if (cm_anchor !== null) {
          const new_a = document.createElement('a');
          new_a.innerText = 'Cookie & Ad Settings';
          new_a.className = 'osano-cm-storage-policy osano-cm-content__link osano-cm-link osano_appended_a';
          new_a.onclick = () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
          };

          // change consent text...
          const consent_span = document.querySelector('.osano-cm-content__message');
          if (consent_span !== null) {
            consent_span.innerHTML = OSANO_HTML_CUSTOMIZATION;
          }

          // change cookie policy link text
          const policy_link = document.querySelector('.osano-cm-storage-policy');
          if (policy_link !== null) {
            policy_link.innerHTML = 'View Privacy Policy';
          }

          root_el.appendChild(new_a);
          clearInterval(cp_interval);
        }
      }
    }, 50);

    const cp_interval2 = setInterval(() => {
      // change cookie preference center verbage
      const cc_original = document.querySelector('#osano-cm-consent--view');
      if (cc_original !== null) {
        const first_p = document.createElement('p');
        first_p.id = 'osano_first_p';
        first_p.innerHTML = OSANO_CC_FIRST_PARAGRAPH_HTML;

        const second_p = document.createElement('p');
        second_p.id = 'osano_second_p';
        second_p.innerText = OSANO_CC_SECOND_PARAGRAPH_HTML;
        //window.alert('here');
        cc_original.innerHTML = '';

        [first_p, second_p].forEach((dom_el) => {
          cc_original.appendChild(dom_el);
        });
        const finding_cookie_policy = document.querySelectorAll('.osano-cm-view__description');
        if (!finding_cookie_policy[1].childElementCount) {
          const new_a = document.createElement('a');
          new_a.rel = 'noopener';
          new_a.className = '';
          new_a.tabIndex = 0;
          new_a.target = '_blank';
          new_a.href = 'https://spotter.la/privacy-policy';
          new_a.className = ' osano-cm-storage-policy osano-cm-content__link osano-cm-link ';
          new_a.id = 'pf_view_cookie_policy';
          new_a.innerText = 'Cookie Policy';
          finding_cookie_policy[1].appendChild(new_a);
        }
        clearInterval(cp_interval2);
      }

      const dns_span = document.querySelector(
        'label[for=osano-cm-drawer-toggle--category_OPT_OUT] > span[role=heading]',
      );
      if (dns_span !== null) {
        dns_span.innerHTML = OSANO_DO_NOT_SELL_OR_SHARE_LANGUAGE;
      }
      const analytics_desc = document.getElementById('osano-cm-drawer-toggle--category_ANALYTICS--description');
      if (analytics_desc !== null) {
        analytics_desc.innerHTML = OSANO_ANALYTICS_DESCRIPTION;
      }

      const do_not_sell_root = document.getElementById('osano-cm-drawer-toggle--category_OPT_OUT--description');
      if (do_not_sell_root !== null) {
        const first_p = document.createElement('p');
        first_p.innerText = OSANO_DNS_REVISION_PAR1;

        const second_p = document.createElement('p');
        second_p.innerText = OSANO_DNS_REVISION_PAR2;

        const new_dns_root = document.createElement('div');
        new_dns_root.id = do_not_sell_root.id;
        new_dns_root.className = do_not_sell_root.className;

        [first_p, second_p].forEach((dom_el) => {
          new_dns_root.appendChild(dom_el);
        });

        do_not_sell_root.replaceWith(new_dns_root);
      }
      //clearInterval(cp_interval2);
    }, 100);

    const cookie_link_interval = setInterval(() => {
      const links = Array.from(document.querySelectorAll < HTMLAnchorElement > ('.osano-cm-content__link'));
      if (links !== null && links !== undefined && links.length > 0) {
        links.forEach((link_el, link_idx) => {
          link_el.id = `osano_bottom_link_${link_idx.toString()}`;
          if (link_idx === 1) {
            const first_link = document.querySelector < HTMLAnchorElement > ('#osano_bottom_link_0');
            if (first_link !== null && first_link !== undefined) {
              const first_link_width = first_link.clientWidth;
              const first_link_x = first_link.offsetLeft;
              link_el.style.position = 'absolute';
              const new_x_offset = (first_link_width + first_link_x + 24).toString();
              link_el.style.left = `${new_x_offset}px`;
              link_el.style.top = `${first_link.offsetTop.toString()}px`;
              setTimeout(() => {
                if (link_idx > 1) {
                  link_el.remove();
                }
              }, 20);
            }
          }
        });
      }
      if (document.querySelector < HTMLButtonElement > ('.osano-cm-accept') !== null) {
        setTimeout(() => {
          const first_link = document.getElementById('osano_bottom_link_0');
          const second_link = document.getElementById('osano_bottom_link_1');
          if (first_link !== null && first_link !== undefined && second_link !== null && second_link !== undefined) {
            const first_link_x = parseInt(first_link.offsetLeft.toString());
            const first_link_width = parseInt(first_link.clientWidth.toString());
            const first_link_y = parseInt(first_link.offsetTop.toString());
            const second_link_x = first_link_x + first_link_width + 24;
            second_link.style.left = `${second_link_x.toString()}px`;
            second_link.style.top = `${first_link_y.toString()}px`;
            //console.log(`${first_link_y.toString()}px`);
          }
        }, 20);
      }
    }, 50);
  }, []);
  if (isIntro) return <Intro onIntroEnd={() => setIsIntro(false)} />;

  return (
    <div
      ref={parentEl}
      className="
      min-h-screen flex flex-col justify-between root-div
      ease-out-expo opacity-0 duration-700 transition-opacity delay-75
      "
    >
      <Scroll callbacks={location} />
      <section className="w-full bg-white flex-grow">
        <ConnectForm />
        { location.pathname !== '/offline-plugin-app-shell-fallback' && (
          <Header defaultHeaderColor={defaultHeaderColor} location={location} />
        )}
        <main id="container" className="font-body w-full">
          {children}
        </main>
        <LandscapeWarning />
        <Footer className={footerColor} />
      </section>
    </div>
  );
};

export default Layout;

/* eslint-disable consistent-return */
/* eslint-disable no-sequences */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Transition } from '@headlessui/react';
import { graphql, useStaticQuery } from 'gatsby';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Constants } from '../constants';
import { Typography } from '../constants/typography';
import { selectFormStatus, toggleForm } from '../state/reducers/utils';
import IconButton from './buttons/icon';
import PrimaryButton from './buttons/primary';
import Grid from './grid';
import IconAsterisk from './icons/asterisk';
import IconClose from './icons/close';

export const FormInput = ({
  name, id, type = 'text', bgColor, textColor, borderColor, onChange,
}) => {
  const [isFocus, setFocus] = useState(false);
  const onFocus = () => {
    setFocus(true);
  };
  const onBlur = () => {
    setFocus(false);
  };
  return (
    <>
      <label
        htmlFor={id}
        className={`${Typography.smallMessage} ${textColor} uppercase block transition-opacity ${isFocus ? 'opacity-100' : 'opacity-0'}`}
      >
        {name}
      </label>
      <div className={`${Typography.body1} ${borderColor} border-b-[1px] max-w-3xl`}>
        <input
          type={type}
          name={name}
          id={id}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
          className={`${bgColor} ${textColor} ${borderColor} block w-full border-0 border-b border-transparent focus:ring-0 pb-2`}
          placeholder={isFocus ? '' : name}
        />
      </div>
    </>
  );
};

export const ConnectForm = ({ className }) => {
  const data = useStaticQuery(graphql`
    {
      connectForm: wpGlobalContent(databaseId: {eq: 53}) {
        connectForm {
          brandsTitle
          brandsParagraph
          creatorsTitle
          companyText
          buttonText
          emailText
          firstNameAndLastNameText
          jobTitleText
          youtubeChannelLinkText
          youtubeChannelNameText
          creatorsParagraph
        }
      }
      thanks: wpGlobalContent(databaseId: {eq: 65}) {
        thanks {
          titleOutline
          titleFill
          subtitle
        }
      }
    }
  `);
  const dispatch = useDispatch();
  const prevScroll = useRef(null);
  const formStatus = useSelector(selectFormStatus);
  const { isOpen } = formStatus;
  const [isThanksOpen, setThanksOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formWarning, setFormWarning] = useState('');
  const formType = formStatus.type;
  const { connectForm } = data.connectForm;
  const { thanks } = data.thanks;
  const type = formType; // Types brands | creators
  const bgColor = type === Constants.BRANDS ? 'bg-black' : 'bg-white';
  const textColor = type === Constants.BRANDS ? 'text-white' : 'text-black';
  const textStrokeColor = type === Constants.BRANDS ? Typography.strokedWhite : Typography.strokedCharcoal;
  const borderColor = type === Constants.BRANDS ? 'border-white' : 'border-black';
  const buttonColor = type === Constants.BRANDS ? 'white' : 'charcoal';
  const title = type === Constants.BRANDS ? connectForm.brandsTitle : connectForm.creatorsTitle;
  const paragraph = type === Constants.BRANDS ? connectForm.brandsParagraph : connectForm.creatorsParagraph;
  const formClass = type === Constants.BRANDS ? 'form-dark' : 'form-light';
  const { buttonText } = connectForm;
  const fieldA = connectForm.firstNameAndLastNameText;
  const fieldB = connectForm.emailText;
  const fieldC = type === Constants.BRANDS ? connectForm.companyText : connectForm.youtubeChannelNameText;
  const fieldD = type === Constants.BRANDS ? connectForm.jobTitleText : connectForm.youtubeChannelLinkText;
  const sendButtonId = type === Constants.BRANDS ? "spotterla_brands_formsend" : null;
  const cancleButtonId = type === Constants.BRANDS ? "spotterla_brands_formcancel" : null;
  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    const form = e.target;
    const inputs = form.querySelectorAll('input');
    const inputA = inputs[0].value;
    const inputB = inputs[1].value;
    const inputC = inputs[2].value;
    const inputD = inputs[3].value;
    if (
      inputA == null || inputA === '',
      inputB == null || inputB === '',
      inputC == null || inputC === '',
      inputD == null || inputD === ''
    ) {
      setFormWarning('Please fill all required field');
      return false;
    }
    setFormWarning('');
    const formData = {
      name: inputA,
      email: inputB,
      inputA: inputC,
      inputB: inputD,
      type,
    };
    const settings = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    };
    try {
      setIsLoading(true);
      const fetchResponse = await fetch(`${process.env.GATSBY_SITE_URL}api/contact-request`, settings);
      const response = await fetchResponse.json();
      setIsLoading(false);
      if (response?.data?.status === 200) {
        setThanksOpen(true);
        setFormWarning('');
      } else {
        setFormWarning(data.message);
      }
    } catch (e) {
      setFormWarning('Something went wrong, please try again.');
      setIsLoading(false);
    }
  });
  useEffect(() => {
    const onKeyDown = (event) => {
      // ESCAPE key pressed
      if (event.keyCode === 27) dispatch(toggleForm(false, type));
    };
    if (isOpen) document.addEventListener('keydown', onKeyDown, false);
    else document.removeEventListener('keydown', onKeyDown, false);
    return () => document.removeEventListener('keydown', onKeyDown, false);
  }, [isOpen]);
  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setThanksOpen(false);
      }, 500);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!window.locoScroll) return;
    if (isOpen) {
      prevScroll.current = window.locoScroll.scroll.instance.scroll.y;
      window.locoScroll.stop();
      setTimeout(() => {
        document.documentElement.classList.add('no-scroll');
      }, 500);
    } else {
      document.documentElement.classList.remove('no-scroll');
      window.locoScroll.start();
      window.locoScroll.scrollTo(prevScroll.current, { duration: 0 });
    }
  }, [isOpen]);

  const CloseButton = () => (
    <IconButton
      buttonId={cancleButtonId}
      className={`
        fixed
        top-3 lg:top-14
        right-3 lg:right-14
        z-2
      `}
      icon={IconClose}
      iconClassName="w-6 h-6 lg:w-8 lg:h-8"
      pathClassName={textColor}
      onClick={() => dispatch(toggleForm(false, type))}
    />
  );
  const animationStates = {
    enter: 'transition-all duration-700 transform ease-out-expo',
    enterFrom: 'opacity-0 translate-y-20',
    enterTo: 'opacity-100 translate-y-0',
    leave: 'transition-all duration-500 transform ease-out-expo',
    leaveFrom: 'opacity-100 translate-y-0',
    leaveTo: 'opacity-0 translate-y-20',
  };
  const Form = () => (
    <Transition
      className="col-span-full"
      show={!isThanksOpen}
      {...animationStates}
    >
      <Grid wrapperClassName="tl:px-0" horizontalPadding="tl:px-0 px-8 md:px-10 lg:px-16">
        <div className="col-span-full w-9/12 lg:col-span-5 lg:w-7/12">
          <div className={`${Typography.formTitle} mb-8`}>{title}</div>
          <div className={`${Typography.body1}`}>{paragraph}</div>
        </div>
        <div className="col-span-full lg:col-span-6 mt-6 lg:mt-0">
          <form key={`${type}-contact-form`} onSubmit={handleSubmit} className={`flex flex-col space-y-5 ${formClass} w-full`}>
            <FormInput name={fieldA} bgColor={bgColor} textColor={textColor} borderColor={borderColor} id="name" />
            <FormInput name={fieldB} bgColor={bgColor} textColor={textColor} borderColor={borderColor} id="email" type="email" />
            <FormInput name={fieldC} bgColor={bgColor} textColor={textColor} borderColor={borderColor} id="inputA" />
            <FormInput name={fieldD} bgColor={bgColor} textColor={textColor} borderColor={borderColor} id="inputB" />
            <div className={`text-red block ${formWarning ? 'opacity-100' : 'oapcity-0'}`}>{formWarning}</div>
            <div className="pt-2 flex justify-center lg:justify-start">
              <div className="flex items-center justify-center space-x-6">
                <PrimaryButton id={sendButtonId} type="submit" color={buttonColor}>{buttonText}</PrimaryButton>
                <div className={`h-8 w-8 transition-all duration-1000 ${isLoading ? 'opacity-100' : 'opacity-0'}`}>
                  <svg className="animate-spin" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                  </svg>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Grid>
    </Transition>
  );
  const ThankYou = () => (
    <Transition
      className="absolute w-full left-0"
      show={isThanksOpen}
      {...animationStates}
    >
      <div className="flex flex-col justify-center items-center text-center col-span-full">
        <IconAsterisk className="w-8 -mb-1 lg:w-14 lg:-mb-4" pathClassName="text-yellow" />
        <h1 className={`block ${Typography.h1} flex flex-col items-center`}>
          <span className={`block ${textStrokeColor}`}>{thanks.titleOutline}</span>
          <span>{thanks.titleFill}</span>
        </h1>
        <h5 className={`${Typography.h5} mt-10 max-w-[280px]`}>{thanks.subtitle}</h5>
      </div>
    </Transition>
  );
  return (
    <Transition
      className={`${className} ${bgColor} ${textColor} fixed w-screen h-screen dark-header header-section z-[9999] overflow-hidden overflow-y-scroll`}
      show={isOpen}
      {...animationStates}
    >
      <Grid
        className="px-0 pt-32 pb-32 lg:p-16 lg:pt-40 lg:pb-32"
        wrapperClassName=""
        horizontalPadding="px-0 md:px-10 lg:px-16 tl:px-0 max-w-[700px] mx-auto lg:max-w-none"
      >
        <ThankYou />
        <Form />
      </Grid>
      <CloseButton />
    </Transition>
  );
};

import React, { useEffect, useState } from 'react';
import { Typography } from '../../constants/typography';
import Button from './button';

const SecondaryButton = ({
  className = '',
  size = 'lg',
  children,
  ...props
}) => {
  const [sizeClasses, setSizeClasses] = useState('');
  useEffect(() => {
    setSizeClasses(
      size === 'lg'
        ? `${Typography.secondaryButton} pt-3.5 px-2 pb-4`
        : `${Typography.primaryButton} pt-3.5 px-2 pb-3.5`,
    );
  }, [size]);
  return (
    <Button
      className={`
        group
        flex items-center
        max-w-full
        align-bottom
        ${sizeClasses}
        ${className}
      `}
      {...props}
    >
      <span className="mr-2 inline-block">
        {children}
      </span>
      <span className="transform translate-y-0 origin-left-center group-hover:animate-nudge">
        →
      </span>
    </Button>
  );
};

export default SecondaryButton;

import React, { forwardRef } from 'react';
import { Link } from 'gatsby';

function Button({
  className, href, to, onClick, children, style, ...props
}, ref) {
  // eslint-disable-next-line no-nested-ternary
  const Tag = href ? 'a' : to ? Link : 'button';
  return (
    <Tag
      ref={ref}
      href={href || null}
      to={to || null}
      className={className}
      style={{ ...style, outline: 0 }}
      onClick={onClick || null}
      {...props}
    >
      {children}
    </Tag>
  );
}

export default forwardRef(Button);

export const OSANO_HTML_CUSTOMIZATION = `<h2>Managing Your Cookies</h2>
  <p>We use cookies on our site to provide you with a better browsing experience, to measure our audience, and to offer you advertising tailored to your areas of interest.</p>
  <p>By using the site, you agree to our use of cookies as described in our Privacy Policy. <span id='rejection_hint'>To reject all optional cookies, select Deny. </span> You can modify your selections through the "Cookie & Ad Settings" available here or in the browser footer.</p>`;

export const OSANO_CC_FIRST_PARAGRAPH_HTML = `We, and third parties we work with, collect personal information about users that visit our digital properties using several common tracking tools. These may include cookies, web beacons, pixels, flash cookies, and similar technologies. We use these technologies as described in our <a href='https://spotter.la/privacy-policy' target='_blank'>Privacy Policy.</a>`;
export const OSANO_CC_SECOND_PARAGRAPH_HTML = `Read below to learn more about the different types of cookies we use and how to change your settings. Blocking some types of cookies may impact your experience on the site. To opt out of these cookies,  toggle the toggle buttons below to the left, ensure the background behind the button is red, and click “Save” once you are done.`;
export const OSANO_DO_NOT_SELL_OR_SHARE_LANGUAGE = `Opt-Out of “Sales” of Personal Information and/or “Sharing” of Personal Information For “Cross-Context Behavioral Advertising”`;
export const OSANO_ANALYTICS_DESCRIPTION = `These cookies help the website operator understand how its website performs, how visitors interact with the site, and whether there may be technical issues.`;
export const OSANO_DNS_REVISION_PAR1 = `To opt out of the “sale” of personal information and/or the “sharing” of personal information for “cross-context behavioral advertising” involving cookies or other similar tracking technologies, please slide the toggle button directly above to the left, ensure the background behind the button is red, and click “Save” once you are done.`;
export const OSANO_DNS_REVISION_PAR2 = `Please note if you use different computers or browsers, you must indicate your choices again on each computer/browser used to access our services. `;

import { Link } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectHeaderColorOverride,
  selectHideHeader,
  selectIsMobile,
  selectIsTablet,
  selectScrollReady,
  setHeaderColorOverride,
} from '../../state/reducers/utils';
import Nav from './nav';
import IconButton from '../buttons/icon';
import Logo from '../icons/logo';
import IconMenu from '../icons/menu';
import { sendTagEvent } from '../../constants/google-tags';

const Header = ({ defaultHeaderColor = 'text-charcoal', location }) => {
  const dispatch = useDispatch();
  const headerRef = useRef();
  const navOpenRef = useRef(false);
  const updatedCanHide = useRef(false);
  const isMobile = useSelector(selectIsMobile);
  const isTablet = useSelector(selectIsTablet);
  const scrollReady = useSelector(selectScrollReady);
  const canHideHeader = useSelector(selectHideHeader);
  const headerColorOverride = useSelector(selectHeaderColorOverride);
  const previousHeaderColor = useRef();
  const [navOpen, setNavOpen] = useState(false);
  const [scrollDir, setScrollDir] = useState('down');
  const [headerColor, setHeaderColor] = useState(defaultHeaderColor);
  const [hide, setHide] = useState(false);
  const [observerRef, setObserverRef] = useState(null);
  const watchDirection = () => {
    const newDirection = document.documentElement.getAttribute('data-direction');
    if (newDirection !== scrollDir) {
      setScrollDir(document.documentElement.getAttribute('data-direction'));
      if (observerRef) {
        setObserverRef(null);
      }
    }
  };
  const changePage = (e, title) => {
    sendTagEvent('click', {
      event_category: 'Top nav',
      event_label: title,
    });
    if (location.pathname !== e) {
      dispatch(setHeaderColorOverride(null));
      navOpenRef.current = false;
      previousHeaderColor.current = null;
    } else {
      setNavOpen(false);
    }
  };
  useEffect(() => {
    setHeaderColorOverride(null);
    setHeaderColor(defaultHeaderColor);
  }, [defaultHeaderColor]);
  useEffect(() => {
    document.documentElement.classList.remove('no-scroll');
  }, [location]);
  const scrollWatch = (e) => {
    if (e.scroll.y < 500) {
      setHide(false);
      return;
    }
    if (e.direction === 'down' && updatedCanHide.current) {
      // setHide(true);
    } else {
      setHide(false);
    }
  };
  useEffect(() => {
    if (headerColorOverride) {
      dispatch(setHeaderColorOverride(null));
      setHeaderColor(headerColorOverride);
    }
  }, [headerColorOverride]);
  useEffect(() => {
    updatedCanHide.current = canHideHeader;
  }, [canHideHeader]);
  useEffect(() => {
    if (scrollReady && window.locoScroll) {
      window.locoScroll.on('scroll', scrollWatch);
    }
  }, [scrollReady]);
  useEffect(() => {
    if (!canHideHeader) {
      setHide(false);
    }
  }, [canHideHeader]);
  useEffect(() => {
    document.documentElement.addEventListener('data-attribute-changed', watchDirection);
    return () => {
      document.documentElement.removeEventListener('data-attribute-changed', watchDirection);
    };
  });
  useEffect(() => {
    if (isMobile !== null || isTablet !== null) {
      if (!observerRef && headerRef.current && !navOpen) {
        let headerOffset;
        if (scrollDir === 'up') {
          headerOffset = headerRef.current.offsetHeight;
        } else {
          headerOffset = headerRef.current.offsetHeight * -1;
        }
        setObserverRef(new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && !navOpenRef.current) {
              if (entry.target.classList.contains('dark-header')) {
                setHeaderColor('text-charcoal');
              } else {
                setHeaderColor('text-white');
              }
            }
          });
        }, { rootMargin: `0px 0px -${window.innerHeight + headerOffset}px 0px` }));
      } else if (observerRef) {
        document.querySelectorAll('.header-section').forEach((section) => { observerRef.observe(section); });
      }
    }
  }, [observerRef, location, isMobile, isTablet]);
  useEffect(() => {
    if (!isMobile && !isTablet) return;
    if (navOpen) {
      previousHeaderColor.current = headerColor;
      setHeaderColor('text-charcoal');
    } else if (navOpenRef.current) {
      setHeaderColor(previousHeaderColor.current);
    }
    navOpenRef.current = navOpen;
  }, [navOpen]);
  return (
    <div
      ref={headerRef}
      className={`
      fixed
      z-50
      w-full
      max-w-screen
      after:block tp:after:hidden lg:after:hidden
      after:absolute
      after:z-0
      after:top-0
      after:h-screen
      after:w-screen
      after:max-h-0
      after:menu-transition
      transition-all
      duration-500
      ${navOpen ? `
        after:bg-white
        after:max-h-screen
        after:menu-transition-open
      ` : `
        after:bg-transparent
        after:menu-transition
      `}
      ${hide ? '-translate-y-full opacity-0' : 'translate-y-0 opacity-100'}
    `}
    >
      <div className={`
        relative
        z-10
        flex justify-between items-center
        sm:h-10
        mt-4
        px-8
        tl:px-16
        lg:px-16
      `}
      >
        <Link
          to="/"
          onClick={() => sendTagEvent('click', {
            event_category: 'Top nav',
            event_label: 'Spotter logo',
          })}
          className={`
            relative
            transition-all duration-500
            sm:pt-1
            z-20
          `}
        >
          <Logo
            className={`w-[100px] lg:w-[124px] lg:h-[30px] ${headerColor}`}
          />
        </Link>
        {
          (isMobile || isTablet) && (
            <IconButton
              className={`
                hidden
                sm:block
                sm:absolute
                sm:right-3
                z-20
              `}
              icon={IconMenu}
              iconClassName="w-5 h-5"
              pathClassName={`${headerColor}`}
              onClick={() => setNavOpen(!navOpen)}
              iconProps={{ open: navOpen }}
            />
          )
        }
        <Nav
          navOpen={navOpen}
          setNavOpen={changePage}
          headerColor={headerColor}
          location={location}
          isMobile={(isMobile || isTablet)}
        />
      </div>
    </div>
  );
};

export default Header;

// const { setItem, getItem } = require('./localStorage');

export const utmSet = () => {

    if (typeof window === 'undefined') {
        return null;
    }

    const utm = window.location.search.includes("utm_") ? window.location.search : "";

    if (!utm.length) return null;

    localStorage.setItem("spotter_utm", utm);

    return utm;

}

export const utmGet = () => {

    if (typeof window === 'undefined') {
        return "";
    }

    return localStorage.getItem("spotter_utm") || "";

}
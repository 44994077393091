/* eslint-disable no-console */
import { Constants } from './index.js';

export const gtmTags = [
  {
    id: process.env.NODE_ENV === Constants.PRODUCTION ? 'GTM-5TLJV92' : 'GTM-MGBMNGJ',
  },
];

export const sendTagEvent = (eventName, params) => {
  if (process.env.NODE_ENV !== Constants.PRODUCTION) {
    console.log('Tag Event:', eventName, '–', params);
  }
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', eventName, params);
  }
};

import React, { useEffect, useState } from 'react';

const IconMenu = ({
  className = '',
  pathClassName = 'text-charcoal',
  open,
}) => {
  const [line1, setLine1] = useState();
  const [line2, setLine2] = useState();
  const [line3, setLine3] = useState();
  const [line4, setLine4] = useState();
  const [group1, setGroup1] = useState();
  const [group2, setGroup2] = useState();
  useEffect(() => {
    if (open) {
      setGroup1('opacity-0');
      setGroup2('opacity-100');
      setLine1('transform translate-y-[7px] hamburger-transition-1');
      setLine2('transform translate-y-[-7px] hamburger-transition-1');
      setLine3('transform rotate-45 hamburger-transition-2 origin-center');
      setLine4('transform -rotate-45 hamburger-transition-2 origin-center');
    } else {
      setGroup1('opacity-100');
      setGroup2('opacity-0');
      setLine1('transform translate-y-0 hamburger-transition-2');
      setLine2('transform translate-y-0 hamburger-transition-2');
      setLine3('hamburger-transition-1 transform rotate-0 origin-center');
      setLine4('hamburger-transition-1 transform rotate-0 origin-center');
    }
  }, [open]);
  return (
    <svg className={`${className}`} width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g className={`transition-all duration-0 delay-200 ${group1}`}>
        <line className={`${pathClassName} ${line1}`} stroke="currentColor" x1="0" y1="17" x2="48" y2="17" strokeWidth="4px" />
        <line className={`${pathClassName} ${line2}`} stroke="currentColor" x1="0" y1="31" x2="48" y2="31" strokeWidth="4px" />
      </g>
      <g className={`transition-all duration-0 delay-200 ${group2}`}>
        <line className={`${pathClassName} ${line3}`} stroke="currentColor" x1="0" y1="24" x2="48" y2="24" strokeWidth="4px" />
        <line className={`${pathClassName} ${line4}`} stroke="currentColor" x1="0" y1="24" x2="48" y2="24" strokeWidth="4px" />
      </g>
    </svg>
  );
};

export default IconMenu;

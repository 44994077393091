import React, { useEffect, useRef, useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { Typography } from '../../constants/typography';
import { Constants } from '../../constants';

const Nav = ({
  location,
  navOpen,
  setNavOpen,
  headerColor,
  isMobile,
}) => {
  const [params, setParams] = useState(null);
  const connectUrl = process.env.GATSBY_CONNECT_URL ? process.env.GATSBY_CONNECT_URL : 'http://localhost:3000';

  useEffect(() => {
    const queryString = location.search;
    setParams(queryString);
  }, [location]);

  const prevScroll = useRef();
  const data = useStaticQuery(graphql`
    {
      footer: wpGlobalContent(databaseId: {eq: 71}) {
        footer {
          socialMediaLinks {
            label
            link
            enabled
            icon {
              localFile {
                publicURL
              }
            }
          }
        }
      }
      misc: wpGlobalContent(databaseId: {eq: 51}) {
        misc {
          creatorsTitle
          brandsTitle
          aboutUsTitle
          connectTitle
        }
      }
    }
  `);
  const closedStyles = `
    sm:translate-y-10
    transform
    left-0
    opacity-0
    transition-all
    sm:pointer-events-none
  `;
  const { misc } = data.misc;
  const { footer } = data.footer;
  const [navClasses, setNavClasses] = useState(closedStyles);
  const [navLinkClasses, setNavLinkClasses] = useState('');

  const navLinks = [
    {
      id: 'link-creators',
      title: 'Creators',
      to: '/creators',
      label: misc.creatorsTitle,
    },
    {
      id: 'link-brands',
      title: 'Brands',
      to: '/brands',
      label: misc.brandsTitle,
    },
    {
      id: 'link-about',
      title: 'About Us',
      to: '/about-us',
      label: misc.aboutUsTitle,
    },
    {
      id: 'link-connect',
      title: 'Connect',
      to: '/connect',
      label: misc.connectTitle,
    },
  ];

  useEffect(() => {
    if (headerColor === 'text-charcoal') {
      setNavLinkClasses(`${Constants.navLink} text-charcoal lg:text-charcoal`);
    } else {
      setNavLinkClasses(`${Constants.navLink} text-charcoal sd:text-white tp:text-white lg:text-white`);
    }
  }, [headerColor]);
  useEffect(() => {
    setNavClasses(
      navOpen ? `
        top-0
        left-0
        transform translate-y-0
        opacity-1
        transition-all 
        pointer-events-auto
      ` : closedStyles,
    );
    if (window.locoScroll) {
      if (navOpen) {
        prevScroll.current = window.locoScroll.scroll.instance.scroll.y;
        window.locoScroll.stop();
        setTimeout(() => {
          document.documentElement.classList.add('no-scroll');
        }, 500);
      } else {
        document.documentElement.classList.remove('no-scroll');
        window.locoScroll.start();
        window.locoScroll.scrollTo(prevScroll.current, { duration: 0 });
      }
    }
  }, [navOpen]);
  const mediaLinks = footer.socialMediaLinks?.filter(
    (item) => item.enabled === true,
  ).map((item) => (
    <div key={`${item.link}-${item.label}`}>
      <a className={`${Typography.footerSocialLink}`} href={item.link} target="_blank" rel="noreferrer">
        <img src={item.icon.localFile.publicURL} alt={item.label} />
      </a>
    </div>
  ));
  return (
    <nav
      className={`
      ${navClasses}
      fixed tp:relative sd:relative lg:relative
      z-0
      sm:true-responsive-h-screen
      flex flex-col tp:flex-row sd:flex-row lg:flex-row
      w-screen tp:w-auto lg:w-auto sd:w-auto
      top-0
      bottom-0 tp:bottom-auto sd:botom-auto lg:bottom-auto
      pt-18 tp:pt-0 sd:pt-0 lg:pt-0
      pb-[6vw] tp:pb-0 sd:pb-0 lg:pb-0
      px-8 tp:px-0 lg:px-0 sd:px-0
      tp:opacity-100 lg:opacity-100
      sd:opacity-100
      sm:-ml-2
      sm:-mr-2
      sm:nav-width
      text-white
    `}
    >

      <div className={`sm:mt-0 flex-1 flex flex-col tp:flex-row sd:flex-row tp:space-x-7 sd:space-x-7 lg:flex-row lg:space-x-7 ${Typography.navLink}`}>
        <>
          {
            navLinks.map((navLink, index) => (
              <Link
                key={navLink.id}
                to={navLink.to}
                onClick={() => setNavOpen(navLink.to, navLink.title)}
                className={`
                  ${navLinkClasses} group
                  ${index === 0 && 'border-t'}
                  ${location?.pathname.includes(navLink.to) && 'navlink-active'}
                `}
              >
                <span className="relative">
                  <span>{navLink.label}</span>
                  <hr
                    className={`
                      sm:hidden
                      absolute
                      top-full
                      mt-1
                      w-full
                      opacity-0 group-hover:opacity-100
                      border-t-2
                      ${headerColor === 'text-charcoal' ? 'border-charcoal' : 'border-white'}
                    `}
                  />
                </span>
              </Link>
            ))
          }
          { process.env.GATSBY_LOGIN_ENABLED === 'true' && !isMobile && (
          <Link
            className={`
              ${navLinkClasses} group
            `}
            id="spotterla_buttonclick_login"
            style={{ marginLeft: '4em' }}
            to={`${connectUrl}/login?${params}`}
          >
            <span className="relative">
              <span>log in</span>
              <hr
                className={`
                  sm:hidden
                  absolute
                  top-full
                  mt-1
                  w-full
                  opacity-0 group-hover:opacity-100
                  border-t-2
                `}
              />
            </span>
          </Link>
          )}
          <div style={{
            position: isMobile ? 'absolute' : '',
            bottom: isMobile ? '100px' : '',
            left: isMobile ? '24px' : '',
            width: isMobile ? 'calc(100% - 3rem)' : '',
            alignItems: isMobile ? 'center' : '',
            margin: isMobile ? '0 auto' : '',
          }}
          >
            <Link
              id="spotterla_buttonclick_getstarted"
              style={{
                paddingTop: isMobile ? '0' : '0.7rem',
                marginTop: isMobile ? '0' : '4px',
                marginLeft: isMobile ? '0' : '0',
                width: isMobile ? '100%' : '150px',
                height: isMobile ? '60px' : '',
                display: isMobile ? 'flex' : 'block',
                textAlign: isMobile ? 'center' : '',
                justifyContent: isMobile ? 'center' : '',
                paddingBottom: isMobile ? '0' : '1.5rem',
              }}
              className={`group rounded-full border-2 border-solid h-11 text-center items-center bg-yellow text-charcoal border-yellow ${!isMobile && 'hover:border-white hover:bg-white'}`}
              to={connectUrl + params}
            >
              <span className="relative">
                <span>get started</span>
              </span>
            </Link>
            { process.env.GATSBY_LOGIN_ENABLED === 'true' && isMobile && (
            <Link
              id="spotterla_buttonclick_login"
              style={{
                height: '60px',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '1em',
              }}
              className={`${navLinkClasses} group rounded-full border-2 border-solid h-11 text-center items-center bg-white text-charcoal border-black`}
              to={`${connectUrl}/login?${params}`}
            >
              <span className="relative">
                <span>log in</span>
              </span>
            </Link>
            )}
          </div>
        </>
      </div>
      <div className={`${Typography.navSocialLink} flex-none space-x-8 flex justify-start sd:hidden tp:hidden lg:hidden`}>
        { mediaLinks }
      </div>
    </nav>
  );
};

// eslint-disable-next-line max-len
const areEqual = (prevProps, nextProps) => prevProps.location.pathname === nextProps.location.pathname && prevProps.location.search === nextProps.location.search && prevProps.navOpen === nextProps.navOpen && prevProps.headerColor === nextProps.headerColor;

export default React.memo(Nav, areEqual);

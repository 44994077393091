import React from 'react';

const IconClose = ({
  className = '',
  pathClassName = 'text-charcoal',
  iconId = ''
}) => (
  <svg className={`${className}`} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg" id={iconId ? iconId+"_svg" : ''}>
    <path className={`${pathClassName}`} fill="currentColor" fillRule="evenodd" clipRule="evenodd" d="M14.0439 1.41L12.6339 0L7.04395 5.59L1.45395 0L0.0439453 1.41L5.63395 7L0.0439453 12.59L1.45395 14L7.04395 8.41L12.6339 14L14.0439 12.59L8.45395 7L14.0439 1.41Z" id={iconId ? iconId+"_path" : ''} />
  </svg>
);

export default IconClose;

import React, { forwardRef } from 'react';
import { Constants } from '../constants';

const Grid = ({
  className = '',
  as = 'div',
  wrapperAs = 'div',
  wrapperClassName = `${Constants.contentMaxWidth}`,
  to = '',
  horizontalPadding = 'px-8 md:px-10 lg:px-16',
  columnConfig = 'grid-cols-4 md:grid-cols-8 lg:grid-cols-12',
  gapConfig = 'gap-4 md:gap-6',
  children,
  displayClass = 'grid',
}, ref) => {
  const Tag = as;
  const Wrapper = wrapperAs;
  return (
    <Wrapper className={`w-full ${horizontalPadding} ${wrapperClassName}`}>
      <Tag ref={ref} to={to} className={`${displayClass} ${gapConfig} ${columnConfig} ${className}`}>
        {children}
      </Tag>
    </Wrapper>
  );
};

export default forwardRef(Grid);

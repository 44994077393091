export const Typography = {
  h1: 'font-medium text-[58px] tp:text-[14vw] sd:text-[11vw] tl:text-[130px] lg:text-[150px] leading-[80%] lg:leading-[85%] tracking-[-0.01em]',
  h2: 'font-medium text-[96px] leading-[90%]',
  h3: 'font-medium text-[48px] tp:text-[54px] tl:text-[56px] sd:text-[56px] lg:text-[64px] leading-[100%]',
  h3Alt: 'font-medium text-[36px] md:text-[48px] sd:text-[56px] lg:text-[64px] leading-[100%]',
  h4: 'font-medium text-[24px] tp:text-[32px] sd:text-[32px] lg:text-[36px] leading-[100%]  ',
  h5: 'font-medium text-[18px] tp:text-[20px] sd:text-[24[x]] lg:text-[24px] leading-[120%]',
  body1: 'font-sans text-[18px] tp:text-[20px] sd:text-[24px] lg:text-[24px] leading-[120%]',
  body2: 'font-sans text-[18px] leading-[110%]',
  body3: 'font-sans text-[16px] leading-[140%]',
  formTitle: 'font-medium text-[72px] leading-[80%]',
  primaryButton: 'font-medium text-[18px] leading-[90%]',
  secondaryButton: 'font-sans text-[24px] leading-[90%]',
  ScrollButton: 'font-sans text-[36px]',
  categoryButton: 'font-sans sm:text-[13px] sm:tracking-[5%] text-[15px] uppercase tracking-widest',
  navLink: 'font-medium text-[32px] md:text-[18px] leading-[95%] md:leading-[90%]',
  navSocialLink: 'font-medium text-[16px] leading-[170%] text-charcoal',
  footerSocialLink: 'font-sans leading-[140%] text-[24px] md:text-[36px]',
  smallMessage: 'font-sans text-[12px] leading-[120%] tracking-[0.01em]',
  eyebrow: 'font-sans sm:text-[13px] text-[15px] leading-[90%] tracking-wider',
  stat: 'font-medium text-[96px] leading-[90%] stroked-text-yellow text-transparent',
  strokedCharcoal: 'stroked-text-charcoal text-transparent',
  strokedCharcoalFill: 'stroked-text-transparent text-charcoal',
  strokedWhite: 'stroked-text-white text-transparent',
  strokedYellow: 'stroked-text-yellow text-transparent',
};

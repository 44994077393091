import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '../constants/typography';
import { selectIsLandscape, selectIsMobile } from '../state/reducers/utils';
import IconAsterisk from './icons/asterisk';

const LandscapeWarning = () => {
  const isMobile = useSelector(selectIsMobile);
  const isLandscape = useSelector(selectIsLandscape);
  const [display, setDisplay] = useState(false);
  const prevScroll = useRef(null);
  useEffect(() => {
    if (isMobile && isLandscape) {
      setDisplay(true);
    } else {
      setDisplay(false);
    }
  }, [isMobile, isLandscape]);

  useEffect(() => {
    if (!window.locoScroll) return;
    if (display) {
      prevScroll.current = window.locoScroll.scroll.instance.scroll.y;
      window.locoScroll.stop();
      setTimeout(() => {
        document.documentElement.classList.add('no-scroll');
      }, 500);
    } else {
      document.documentElement.classList.remove('no-scroll');
      window.locoScroll.start();
      window.locoScroll.scrollTo(prevScroll.current, { duration: 0 });
    }
  }, [display]);
  return (
    <>
      {
        display && (
          <div className="fixed top-0 left-0 w-screen h-screen bg-charcoal text-yellow flex flex-col justify-center items-start px-16 z-50">
            <IconAsterisk className="w-[40px]" pathClassName="text-yelllow" />
            <p className={`${Typography.body1} max-w-[400px] mt-4`}>
              This site was designed for portrait orientation.
              Please rotate your device to portrait orientation.
            </p>
          </div>
        )
      }
    </>
  );
};

export default LandscapeWarning;

import React, { useEffect, useState } from 'react';
import { Typography } from '../../constants/typography';
import Button from './button';

const PrimaryButton = ({ 
  buttonId = '',
  className = '',
  variant = 'default',
  color = 'charcoal',
  hoverClassName = 'hover:border-yellow hover:bg-yellow hover:text-charcoal',
  active = false,
  children,
  ...props
}) => {
  const [buttonClasses, setButtonClasses] = useState('');

  useEffect(() => {
    let bc = `border-solid border-2 ${hoverClassName} `;
    if (active) {
      bc += 'bg-yellow text-charcoal border-yellow';
    } else if (variant === 'outlined') {
      bc += color === 'charcoal' ? 'border-charcoal text-charcoal' : 'border-white text-white';
    } else {
      bc += color === 'charcoal' ? 'border-charcoal bg-charcoal text-white' : 'border-white bg-white text-charcoal';
    }
    setButtonClasses(bc);
  }, [active, color]);

  return (
    <Button
      id={buttonId}
      className={`
        w-60
        max-w-full
        h-14 px-2
        flex-0
        pb-[2px]
        rounded-full
        text-center
        no-underline
        flex
        flex-col
        justify-center
        items-center
        ${buttonClasses}
        ${Typography.primaryButton}
        ${className}
      `}
      {...props}
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;

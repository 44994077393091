/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Grid from './grid';
import SecondaryButton from './buttons/secondary';
import { Typography } from '../constants/typography';
import { sendTagEvent } from '../constants/google-tags';

const Footer = ({ className = 'bg-tan' }) => {
  const data = useStaticQuery(graphql`
    {
      footer: wpGlobalContent(databaseId: {eq: 71}) {
        footer {
          connectTitle
          copyrightText
          socialMediaLinks {
            label
            link
            enabled
            icon {
              localFile {
                publicURL
              }
            }
          }
        }
      }
      misc: wpGlobalContent(databaseId: {eq: 51}) {
        misc {
          spotterContactEmail
          addressLabel
          addressGoogleMapsLink

        }
      }
    }
  `);
  const { footer } = data.footer;
  const { misc } = data.misc;
  const mediaLinks = footer.socialMediaLinks?.filter(
    (item) => item.enabled === true).map((item) => (
      <div key={`${item.link}-${item.label}`}>
        <a className={`${Typography.footerSocialLink}`} href={item.link} target="_blank" rel="noreferrer">
          <img src={item.icon.localFile.publicURL} alt={item.label} />
        </a>
      </div>
  ));
  const onLegalPage = (id) => {
    sendTagEvent('click', {
      event_category: 'Footer',
      event_label: id,
    });
  };
  const cookieManagerOpen = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
  };
  return (
    <div data-scroll-section className={`${className} pt-12 lg:pt-20 pb-7 dark-header header-section px-0 sd:px-0 lg:p-16 relative z-20`}>
      <Grid>
        <div className={`
          col-span-full lg:col-span-5
          ${Typography.footerSocialLink}
          !font-medium
          text-left
        `}
        >
          {footer.connectTitle}
          <SecondaryButton
            className={`block pl-0 ${Typography.footerSocialLink}`}
            href={`mailto:${misc.spotterContactEmail}`}
            onClick={() => {
              sendTagEvent('click', {
                event_category: 'Footer',
                event_label: 'contact@spotter.la',
              });
            }}
          >
            {misc.spotterContactEmail}
          </SecondaryButton>
        </div>
        <a
          href={misc.addressGoogleMapsLink}
          target="_blank"
          rel="noreferrer"
          className={`
            flex flex-col
            col-span-full lg:col-span-5
            my-10 lg:my-0
            text-left
            ${Typography.footerSocialLink}
            !leading-[120%]
          `}
          dangerouslySetInnerHTML={{ __html: misc.addressLabel }}
        />
        <div className={`
          flex justify-start lg:justify-end space-x-5
          col-span-full lg:col-span-2
          my-10 lg:my-0 lg:pt-2
          text-left
        `}
        >
          {mediaLinks}
        </div>
      </Grid>
      <Grid className="lg:mt-40">
        <div className={`
          ${Typography.smallMessage}
          col-span-full lg:col-span-6
          text-left
        `}
        >
          {footer.copyrightText}
        </div>
        <div className={`${Typography.smallMessage} col-span-full lg:col-span-5`}>
          <div className="flex justify-start space-x-3">
            <Link className="hover:underline" to="/aup" onClick={() => onLegalPage('Acceptable Use Policy')}>Acceptable Use Policy</Link>
            <Link className="hover:underline" to="/spotter-platform-t&c" onClick={() => onLegalPage('Spotter Platform T&C')}>Spotter Platform T&C</Link>
            <Link className="hover:underline" to="/privacy-policy" onClick={() => onLegalPage('Privacy Policy')}>Privacy Policy</Link>
            <Link className="hover:underline" to="/terms-of-service" onClick={() => onLegalPage('Terms of Service')}>Terms of Service</Link>
            <Link className="hover:underline" to="/digital-millennium-copyright-act-policy" onClick={() => onLegalPage('DMCA')}>DMCA Policy</Link>
            <Link className="hover:underline" to="/do_not_sell_or_share_my_personal_information" onClick={() => onLegalPage('Do Not Sell or Share My Personal Information')}>Do Not Sell or Share My Personal Information</Link>
			      <a className="hover:underline cursor-pointer" onClick={() => cookieManagerOpen()}>Cookie & Ad Settings</a>
          </div>
        </div>
      </Grid>
    </div>
  );
};

export default Footer;

/* eslint-disable import/no-unresolved */
/* eslint-disable no-use-before-define */
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';
import { Constants } from '../constants';
import { utmSet } from '../utils/utmExtractor';

// Capture any utm variables and save to localStorage
utmSet();

// Redirect from spotter.la to .com
// Uncomment to use
// if (typeof window !== 'undefined') {

//   const { hostname, href } = window.location;

//   if (hostname.includes('spotter.la')) {
//     window.location.href = href.replace('spotter.la', 'spotter.com');
//   }
// }

const SEO = ({
  title, description, image, lang,
}) => {

  const { pathname } = useLocation();
  const baseUrl = Constants.BASE_URL;
  const { site } = useStaticQuery(query);
  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    defaultImage,
  } = site.siteMetadata;
  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${baseUrl}${image || defaultImage}`,
    url: `${baseUrl}${pathname}`
  };

  return (
    <Helmet htmlAttributes={{ lang }} title={seo.title} titleTemplate={titleTemplate}>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="robots" content="all" />

      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.url && <link rel="canonical" href={seo.url} />}

      {seo.title && <meta property="og:title" content={seo.title} />}

      <meta property="og:type" content="website" />

      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}

      {seo.image && <meta property="og:image" content={seo.image} />}

      <meta name="twitter:card" content="summary_large_image" />

      {seo.title && <meta name="twitter:title" content={seo.title} />}

      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}

      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  lang: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
};

SEO.defaultProps = {
  lang: 'en',
  title: null,
  description: null,
  image: null,
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        defaultImage: image
      }
    }
  }
`;

export default SEO;
